export const SIDEBAR_CLICKED           = 'SIDEBAR_CLICKED'
export const UPDATE_DASHBOARDINFO      = 'UPDATE_DASHBOARDINFO'
export const BUTTON_TOGGLE_NAVBAR      = 'BUTTON_TOGGLE_NAVBAR'
export const BUTTON_TOGGLE_NAVBARMINI  = 'BUTTON_TOGGLE_NAVBARMINI'
export const UPDATE_LOAD_USERINFO      = 'UPDATE_LOAD_USERINFO'
export const UPDATE_PAGE               = 'UPDATE_PAGE'
export const UPDATE_PAGE_DATA          = 'UPDATE_PAGE_DATA'
export const UPDATE_PAGE_PERMISSION    = 'UPDATE_PAGE_PERMISSION'
export const PAGE_LOADING              = 'PAGE_LOADING'
export const PAGE_AJAX_ERROR           = 'PAGE_AJAX_ERROR'
export const PAGE_ERROR                = 'PAGE_ERROR'
export const TOGGLE_MODAL              = 'TOGGLE_MODAL'
export const UPDATE_MODAL_BODY         = 'UPDATE_MODAL_BODY'
export const UPDATE_MODAL_SIZE         = 'UPDATE_MODAL_SIZE'
export const TOGGLE_MODAL2             = 'TOGGLE_MODAL2'
export const UPDATE_MODAL_BODY2        = 'UPDATE_MODAL_BODY2'
export const UPDATE_MODAL_SIZE2        = 'UPDATE_MODAL_SIZE2'
export const CHANGE_ACCESSTOKEN        = 'CHANGE_ACCESSTOKEN'
export const LOADING                   = 'LOADING'
export const UPDATE_SIDEBAR_LIST       = 'UPDATE_SIDEBAR_LIST'
export const UPDATE_MESSAGE_NOTIF      = 'UPDATE_MESSAGE_NOTIF'
